import React, { useEffect, useState } from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { Link } from 'gatsby';

import { StaticImage } from 'gatsby-plugin-image';

import '../generic-page.scss';
import './tier-list.scss';
import { Accordion, Button, Card } from 'react-bootstrap';
import {
  faAnglesDown,
  faArrowDown,
  faArrowUp,
  faAsterisk,
  faBriefcaseMedical,
  faFlask,
  faHandFist,
  faStar,
  faVirus
} from '@fortawesome/free-solid-svg-icons';
import {
  faFaceSadTear,
  faStar as faStarEmpty
} from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import lodash from 'lodash';
import { FilterBar } from '../../modules/cs/common/components/filter-bar/filter-bar';
import { HSRCharacter } from '../../modules/hsr/common/components/hsr-character';
import Switch from 'react-switch';
import { CustomPopover } from '../../modules/cs/common/components/custom-popover';
import { HSRRatings } from '../../modules/hsr/data/hsr-ratings';

const HSRTierListPage: React.FC = () => {
  const [visibleCharacters, setVisibleCharacters] = useState(HSRRatings);

  const [eidolonSpecial, setEidolonSpecial] = useState(true);
  const [activeFilters, setActiveFilters] = useState(null);
  const [sortOption, setSortOption] = useState(null);

  const filterConfig = {
    filters: [
      {
        key: 'searchText',
        type: 'search',
        placeholder: 'Search characters...'
      },
      {
        key: 'rarity',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          { label: '4★', value: '4' },
          { label: '5★', value: '5' }
        ]
      },
      {
        key: 'element',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Physical',
            tooltip: 'Physical',
            image: (
              <StaticImage
                src="../../images/starrail/icons/ele_physical.png"
                width={24}
                alt="Physical"
              />
            )
          },
          {
            value: 'Fire',
            tooltip: 'Fire',
            image: (
              <StaticImage
                src="../../images/starrail/icons/ele_fire.png"
                width={24}
                alt="Fire"
              />
            )
          },
          {
            value: 'Ice',
            tooltip: 'Ice',
            image: (
              <StaticImage
                src="../../images/starrail/icons/ele_ice.png"
                width={24}
                alt="Ice"
              />
            )
          },
          {
            value: 'Lightning',
            tooltip: 'Lightning',
            image: (
              <StaticImage
                src="../../images/starrail/icons/ele_lightning.png"
                width={24}
                alt="Lightning"
              />
            )
          },
          {
            value: 'Wind',
            tooltip: 'Wind',
            image: (
              <StaticImage
                src="../../images/starrail/icons/ele_wind.png"
                width={24}
                alt="Wind"
              />
            )
          },
          {
            value: 'Quantum',
            tooltip: 'Quantum',
            image: (
              <StaticImage
                src="../../images/starrail/icons/ele_quantum.png"
                width={24}
                alt="Quantum"
              />
            )
          },
          {
            value: 'Imaginary',
            tooltip: 'Imaginary',
            image: (
              <StaticImage
                src="../../images/starrail/icons/ele_imaginary.png"
                width={24}
                alt="Imaginary"
              />
            )
          }
        ]
      },
      {
        key: 'path',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Abundance',
            tooltip: 'Abundance',
            image: (
              <StaticImage
                src="../../images/starrail/icons/path_abundance.png"
                width={24}
                alt="Abundance"
              />
            )
          },
          {
            value: 'Destruction',
            tooltip: 'Destruction',
            image: (
              <StaticImage
                src="../../images/starrail/icons/path_destruction.png"
                width={24}
                alt="Destruction"
              />
            )
          },
          {
            value: 'Erudition',
            tooltip: 'Erudition',
            image: (
              <StaticImage
                src="../../images/starrail/icons/path_erudition.png"
                width={24}
                alt="Erudition"
              />
            )
          },
          {
            value: 'Harmony',
            tooltip: 'Harmony',
            image: (
              <StaticImage
                src="../../images/starrail/icons/path_harmony.png"
                width={24}
                alt="Harmony"
              />
            )
          },
          {
            value: 'Hunt',
            tooltip: 'Hunt',
            image: (
              <StaticImage
                src="../../images/starrail/icons/path_hunt.png"
                width={24}
                alt="Hunt"
              />
            )
          },
          {
            value: 'Nihility',
            tooltip: 'Nihility',
            image: (
              <StaticImage
                src="../../images/starrail/icons/path_nihility.png"
                width={24}
                alt="Nihility"
              />
            )
          },
          {
            value: 'Preservation',
            tooltip: 'Preservation',
            image: (
              <StaticImage
                src="../../images/starrail/icons/path_preservation.png"
                width={24}
                alt="Preservation"
              />
            )
          },
          {
            value: 'Remembrance',
            tooltip: 'Remembrance',
            image: (
              <StaticImage
                src="../../images/starrail/icons/path_remem.png"
                width={24}
                alt="Remembrance"
              />
            )
          }
        ]
      },
      { key: 'reset', type: 'reset' }
    ],
    defaults: null
  };
  const handleFilterUpdates = (choices) => {
    setActiveFilters({
      ...choices
    });
  };
  useEffect(() => {
    let filtered = HSRRatings;

    if (sortOption === 'moc' && eidolonSpecial === false) {
      setSortOption('moc_special');
    }

    if (sortOption === 'moc_special' && eidolonSpecial === true) {
      setSortOption('moc');
    }

    if (sortOption === 'pure' && eidolonSpecial === false) {
      setSortOption('pure_special');
    }

    if (sortOption === 'pure_special' && eidolonSpecial === true) {
      setSortOption('pure');
    }

    if (sortOption === 'apo' && eidolonSpecial === false) {
      setSortOption('apo_special');
    }

    if (sortOption === 'apo_special' && eidolonSpecial === true) {
      setSortOption('apo');
    }

    if (activeFilters) {
      if (activeFilters.searchText) {
        filtered = filtered.filter(
          (emp) =>
            emp.name
              .toLowerCase()
              .indexOf(activeFilters.searchText.toLowerCase()) > -1
        );
        filtered = lodash.uniqBy(filtered, 'id');
      }
      if (activeFilters.rarity && activeFilters.rarity !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.rarity.toString() === activeFilters.rarity.toLowerCase()
        );
      }
      if (activeFilters.element && activeFilters.element !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.element.toLowerCase() === activeFilters.element.toLowerCase()
        );
      }
      if (activeFilters.path && activeFilters.path !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.path.toLowerCase() === activeFilters.path.toLowerCase()
        );
      }
    }

    if (sortOption) {
      filtered = filtered.sort((a, b) =>
        a[sortOption] < b[sortOption] ? 1 : -1
      );
    }

    setVisibleCharacters(filtered);
  }, [activeFilters, visibleCharacters, sortOption, eidolonSpecial]);

  const sortingOptions = [
    { value: 'moc', label: 'Memory of Chaos' },
    { value: 'pure', label: 'Pure Fiction' },
    { value: 'apo', label: 'Apocalyptic Shadow' }
  ];

  let defaultSortOption = sortOption
    ? sortingOptions.find((opt) => opt.value === sortOption)
    : null;
  if (defaultSortOption === null) {
    // eslint-disable-next-line prefer-destructuring
    defaultSortOption = sortingOptions[0];
    setSortOption(defaultSortOption.value);
  }

  return (
    <DashboardLayout className={'generic-page'} game="hsr">
      <ul className="breadcrumb">
        <li>
          <Link to="/star-rail/">Honkai: Star Rail</Link>
        </li>
        <li className="divider">/</li>
        <li>Tier list</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/starrail/categories/category_tierlist.png"
            alt="Tier list"
          />
        </div>
        <div className="page-details">
          <h1>Honkai: Star Rail Tier list (March 2025)</h1>
          <h2>
            The most popular tier list for Honkai: Star Rail that rates all
            available characters by their performance in Memory of Chaos, Pure
            Fiction and Apocalyptic Shadow.
          </h2>
          <p>
            Last updated: <strong>27/03/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297004"></div>
      {/* <div className="afk-ad-banner">
        <div className="cta">
          <h4>New Season "Waves of Intrigue" is now live in AFK Journey</h4>
          <p>Unveil the mysteries of Rustport and uncover new adventures!</p>
          <OutboundLink href="https://bit.ly/PrydwenHSR" target="_blank">
            <Button variant="primary">Download Now</Button>
          </OutboundLink>
        </div>
      </div> */}
      <div className="tier-list-page">
        <SectionHeader title="Honkai: Star Rail Tier list" />
        <Accordion defaultActiveKey="0" className="tier-list-accordion">
          <Accordion.Item eventKey="0">
            <Accordion.Header>About the Tier List</Accordion.Header>
            <Accordion.Body>
              <p>
                Please keep in mind that Honkai: Star Rail{' '}
                <strong>is a game where team building matters most</strong> and
                while our tier list takes the optimal setup into account, a lot
                of characters can work and do well - even those ranked lower -
                when you invest into them. Also for story or lower difficulties
                of Simulated Universe, you don't need to worry about ratings and
                tiers. You can safely clear that content even with F2P
                characters as long as you build your team correctly.
              </p>
              <p>
                These tier lists rate characters based on their average
                performance in{' '}
                <strong>
                  Memory of Chaos, Pure Fiction and Apocalyptic Shadow
                  regardless of turbulence, whimsicality and cacophony
                </strong>{' '}
                <strong className="red">(last 3 phases specifically)</strong>.
                Characters rated higher will perform well without the need to
                rely on these and will only benefit more from receiving them. As
                new mechanics, characters and challenges are introduced each
                tier list will be updated.
              </p>
              <p>
                <strong>
                  Important! Characters are ordered alphabetically within a tier
                </strong>
                .
              </p>
              <p>Available tier lists:</p>
              <ul>
                <li>
                  <strong>Memory of Chaos (MoC)</strong> - how the character
                  performs in the Memory of Chaos. Blast and single target
                  damage are important here while AoE has niche uses against
                  some bosses.
                </li>
                <li>
                  <strong>Pure Fiction (PF)</strong> - how the character
                  performs in the Pure Fiction. AoE is king here, followed by
                  Blast while single target damage is mostly useless.
                </li>
                <li>
                  <strong>Apocalyptic Shadow (AS)</strong> - how the character
                  performs in the Apocalyptic Shadow. Single target and Break
                  potential are heavily favored here.
                </li>
              </ul>
              <p>
                <strong>About the ratings</strong>:
              </p>
              <p>
                To decide the ratings we use a combination of the data we gather
                for every mode (the Analytics for every mode you can find in the
                left menu), our own testing that's done inline with the criteria
                (check below) and the last element is community feedback we
                receive - either from Reddit, YouTube or our own Discord.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Categories & Tags</Accordion.Header>
            <Accordion.Body>
              <h6>Categories</h6>
              <ul className="hsr-categories">
                <li>
                  <strong className="dps">DPS</strong> - Characters that mainly
                  focus on dealing damage and being the main source of it in the
                  team they are placed in,
                </li>
                <li>
                  <strong className="specialist">Support DPS</strong> -
                  Characters that fall within this category are very rarely
                  played as the primary or sole damage dealer of the team and
                  instead almost always play alongside another character that
                  fills that role instead. Support DPS are still built with the
                  intention to deal damage themselves but also have a kit
                  containing buffs/debuffs to bolster their team's performance
                  and/or have abilities that are directly dependent on others in
                  order to function.
                </li>
                <li>
                  <strong className="amplifier">Amplifier</strong> - Characters
                  that do not focus on dealing damage but instead amplify the
                  damage other teammates deal by granting allies buffs or
                  debuffing enemies,
                </li>
                <li>
                  <strong className="sustain">Sustain</strong> - Characters who
                  are capable of keeping the team alive with their abilities.
                </li>
              </ul>
              <h6>Special tags</h6>
              <p>
                Tags are split into 3 categories:{' '}
                <strong className="positive">Pros</strong>,{' '}
                <strong className="negative">Cons</strong> and{' '}
                <strong className="archetype">Archetype</strong> which represent
                the most defining features of a character's kit and the current
                meta archetypes they fit into. Archetype tags indicate a
                connection between characters with that tag e.g. Acheron
                requires debuffs to work and Silver Wolf applies them. Here is a
                full list of all current tags and what they mean:
              </p>
              <ul>
                <li>
                  <strong className="archetype">Break</strong> - the character
                  belongs to the Break archetype focusing on Break Effect and
                  Break Efficiency,
                </li>
                <li>
                  <strong className="archetype">Debuff</strong> - the character
                  belongs to the Debuff archetype applying one or many debuffs
                  or has a kit that directly relies on them to function,
                </li>
                <li>
                  <strong className="archetype">DoT</strong> - the character
                  belongs to the Damage-over-Time archetype specializing in
                  applying and/or amplifying DoT effects,
                </li>
                <li>
                  <strong className="archetype">FuA</strong> - the character
                  belongs to the Follow-up Attack archetype benefitting from,
                  buffing or delivering powerful Follow-Up Attacks.,
                </li>
                <li>
                  <strong className="archetype">Summon</strong> - the character
                  fights alongside summons or other entities,
                </li>
                <li>
                  <strong className="positive">Advance</strong> - the character
                  can manipulate the turn order by either action advancing their
                  allies or themselves,
                </li>
                <li>
                  <strong className="positive">Buff</strong> - the character can
                  apply various Buffs to help their teammates,
                </li>
                <li>
                  <strong className="positive">Delay</strong> - the character
                  can delay enemies and hinder their actions in battle,
                </li>
                <li>
                  <strong className="positive">Energy</strong> - the character
                  can recover the teammates energy,
                </li>
                <li>
                  <strong className="positive">SP-Friendly</strong> - the
                  character is substantially more skill point friendly than
                  other characters in their role.
                </li>
                <li>
                  <strong className="negative">SP-Unfriendly</strong> - the
                  character is substantially more skill point un-friendly than
                  other characters in their role.
                </li>
                <li>
                  <strong className="negative">Partner</strong>{' '}
                  <span className="tag new">New!</span> - the character is
                  incredibly reliant on being partnered with one specific
                  character in order to function at the highest level.
                  Characters marked with this tag will perform one (but
                  sometimes even more) tiers lower than their listed position on
                  the tier list without those characters alongside them.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>Ratings & Meta Lines</Accordion.Header>
            <Accordion.Body>
              <h5>Meta Lines</h5>
              <p>
                Meta Lines categorize each section of the tier list into a power
                bracket. Here are the four brackets:
              </p>
              <ul>
                <li>
                  <strong className="tier-05">
                    Apex Characters (T0 & T0.5)
                  </strong>{' '}
                  - characters in this bracket are the cream of the crop. They
                  can do outrageous amount of damage, provide massive buffs (or
                  debuffs) or keep the team alive no matter what the enemy
                  throws at them. They simply can achieve the best, most
                  consistent and accessible results in their best teams,
                  allowing you to easily clear the content,
                </li>
                <li>
                  <strong className="tier-2">
                    Meta Characters (T1, T1.5, T2)
                  </strong>{' '}
                  - characters in this bracket make clearing the content a
                  breeze, but compared to the Apex characters they require more
                  work from your side. Either their team options are more
                  restrictive, they require bigger Relic investment or they
                  possess a notable weakness or downside. Still, they are all
                  great and won't disappoint you if you give them what they need
                  to shine,
                </li>
                <li>
                  <strong className="tier-4">Niche Characters (T3 & T4)</strong>{' '}
                  - characters in this bracket are lacking in one or many fields
                  of their role. These characters still possess the ability to
                  clear all content with the right build, endgame blessing, team
                  or enemy line-up but have much lower play rates and success
                  rates than Meta and Apex characters,
                </li>
                <li>
                  <strong className="tier-5">The Forgotten Ones (T5)</strong> -
                  characters in this bracket struggle. A lot. Either their kits
                  simply don't work in the mode or they require a ton of
                  investment and love to be able to work. Just avoid them unless
                  your husbando or waifu landed here and you believe in the
                  power of love.
                </li>
              </ul>
              <h5>Half tiers</h5>
              <p>
                To further define the tier list and correctly represent the
                power level of each character in relation to each other we have
                added half tiers to the higher tier brackets. Under our previous
                system a tier rating jump of 1 tier rating made it difficult to
                position characters accurately, often leading to multiple
                characters ending up in the same tier despite a clear power and
                utility difference.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>Criteria</Accordion.Header>
            <Accordion.Body>
              <h6>Criteria</h6>
              <ul>
                <li>Manual play,</li>
                <li>Using optimal team composition/support characters,</li>
                <li>Character level 80,</li>
                <li>
                  All Major Traces unlocked and skills upgraded to level 10,
                </li>
                <li>
                  Character equipped with level 80{' '}
                  <strong>non-limited 4★ or 5★ Light Cone</strong> (including
                  the 'standard' Light Cones),
                </li>
                <ul>
                  <li>
                    Battle Pass Light Cones aren't taken into account because
                    you can't obtain them without spending,
                  </li>
                </ul>
                <li>
                  Character equipped with 5★ upgraded gear with optimal Main
                  Stats and Sub Stats (with 24 desired sub stats out of a
                  possible 54),
                </li>
                <li>Eidolon level 0 for 5★ characters,</li>
                <li>Eidolon level 0/6 for 4★ characters.</li>
              </ul>
              <h6>Roles</h6>
              <p>
                We compare characters within their role - so DPS are compared to
                other DPS and the ratings are adjusted based on their
                performance within the role. Don't try to compare characters
                between roles (for example an Amplifier with a DPS), because
                their ratings mean different things.
              </p>
              <ul className="hsr-categories">
                <li>
                  <strong className="dps">DPS</strong> - Rating is heavily
                  influenced based on the characters ability to deal enough
                  damage to achieve the 3-star objective, the easier and more
                  quickly achieved the higher the rating,
                </li>
                <li>
                  <strong className="specialist">Support DPS</strong> - Rating
                  is heavily influenced based on the damage they provide, the
                  buffs and debuffs or efficiency boost these characters create
                  or the utility they provide against certain encounters,
                </li>
                <li>
                  <strong className="amplifier">Amplifier</strong> - Rating is
                  influenced based on the damage amplification abilities of the
                  character and the utility they provide to the team,
                </li>
                <li>
                  <strong className="sustain">Sustain</strong> - Rating is
                  influenced based on the characters ability to keep the team
                  alive and the utility they provide. The more utility and
                  higher the safety, the higher the rating.
                </li>
              </ul>
              <h6>Other criteria affecting the ratings</h6>
              <p>
                Each tier list rates characters based on their average value in
                the specific game mode it represents.{' '}
                <strong>
                  The higher a character is rated on the tier list the greater
                  their power and more widely they can be used effectively in
                  the mode
                </strong>
                , helping the players in getting all rewards every cycle.{' '}
                <strong className="red">
                  We aren't looking at 0-cycle potential
                </strong>
                . We consider:
              </p>
              <ul>
                <li>
                  <strong>Flexibility</strong> - how the characters kit
                  functions against various enemy types, amounts of enemies and
                  their ability to perform when not fighting against enemies
                  vulnerable to their element,
                </li>
                <li>
                  <strong>Investment</strong> - some characters require specific
                  limited 5★ units, heavy investment in certain relic sets or an
                  abundance of sub-stats in order to function at the highest
                  level. Each of these points impacts the rating in a negative
                  way. - the more the character requires to perform well, the
                  bigger the impact,
                </li>
                <li>
                  <strong>Utility</strong> - recently released characters
                  possess abilities that lie beyond their defined role, for
                  example Amplifiers/Sustains that can deal decent damage. The
                  more a character helps the team outside of their role, the
                  bigger positive impact on their rating it has.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>
              Changelog
              {/* <span className="tag new">Updated!</span> */}
            </Accordion.Header>
            <Accordion.Body>
              <h6>
                <strong>27/03/2025</strong>
              </h6>
              <p>
                This phase of Pure Fiction has continued the trends we’ve seen
                in the mode since the rework while maintaining the sharp
                difficulty increase that occurred in 3.0. The major exception to
                this trend has been the release of{' '}
                <HSRCharacter mode="inline" slug="tribbie" enablePopover /> who
                has now had her first complete Pure Fiction release since her
                debut. To say Tribbie is a powerful force in Pure Fiction would
                be an understatement - the difference between teams that have
                Tribbie present on them and those that don’t scoring wise is
                tremendous, forming <strong>“The Tribbie Gap”</strong>.
              </p>
              <p>
                Fortunately, or unfortunately, Tribbie can be used with every
                character (your DPS will fight over her), so the gains accrued
                are somewhat balanced. Another somewhat concerning trend
                continues to occur in Pure Fiction though - and it's the
                widening gap between certain power houses belonging to “Big
                Erudition”. Characters like{' '}
                <HSRCharacter mode="inline" slug="jade" enablePopover />{' '}
                <HSRCharacter mode="inline" slug="the-herta" enablePopover />{' '}
                and <HSRCharacter mode="inline" slug="argenti" enablePopover />{' '}
                all continue to score absurdly while many other mainstays
                continue to see a drop in their average score over last few
                phases.
              </p>
              <p>
                Due to the fact above, we’re making some adjustments to the tier
                list as well as marking (or remarking) a few characters with the
                watchlist tag. As Pure Fiction settles in, it’s clear after the
                rework some characters are far more consistent than others and
                consistency is a big part of what it means to remain in the Apex
                Tier. Here are the changes and watchlists we’re moving forward
                with this time:
              </p>
              <ul>
                <li>
                  <HSRCharacter mode="inline" slug="herta" enablePopover /> T0{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T0.5. In previous iterations of our tier list, Herta has done
                  enough to be worthy of Tier 0 being a more accessible and
                  still powerful alternative to Jade albeit behind her in
                  performance. Now with the release of Tribbie who arguably
                  performs above Jade the bar has been raised. We feel Jade is
                  worthy of sitting alongside Tribbie for now but Herta doesn’t
                  quite meet the bar. We’re lowering her down a tier for this
                  reason.
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="aglaea" enablePopover />{' '}
                  T1.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T1. Aglaea continues her hallmark creep up all of our tier
                  lists with her latest achievement being Pure Fiction. Aglaea
                  has demonstrated competent scores in the mode since her
                  release, matching some of her higher rated Lightning DPS peers
                  blow for blow. With her most recent results, we’re raising her
                  tier again.
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="jing-yuan" enablePopover />{' '}
                  T0.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T1. Last phase we had Jing Yuan on the watchlist in order to
                  see if he should remain in the Apex tier. This phase, Jing
                  Yuan unfortunately hasn’t bounced back quite in the way we
                  hoped. When paired with Tribbie his scores were strong, like
                  most characters experiencing the “Tribbie gap” but without her
                  much less so. Right now we feel Jing Yuan is most accurately
                  placed alongside Aglaea as such we’re moving him inline with
                  her new rating for now.
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="kafka" enablePopover /> &{' '}
                  <HSRCharacter mode="inline" slug="black-swan" enablePopover />{' '}
                  T1.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T2. Well, It's that time again - DoTs going down the tier
                  list. The game continues to get harder and no DoT support has
                  been released or announced. We look forward to Hoyo's planned
                  buffs for older characters and have high hopes for DoT support
                  soon (Hoyo please).
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="mydei" enablePopover /> T2{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T3. Mydei has performed below expectations in Pure Fiction. Is
                  he capable of scoring competently in sustainless teams
                  alongside helpers like Tribbie? Yes. Is that realistic for
                  most people, worth doing or worthy of remaining in the “meta”
                  tiers? At this stage we don’t think so. Because of this we’re
                  lowering him for now.
                </li>
                <li>
                  <HSRCharacter
                    mode="inline"
                    slug="trailblazer-harmony"
                    enablePopover
                  />{' '}
                  T1{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T1.5. Break doesn’t have the same edge it used to in Pure
                  Fiction when compared to the CRIT-based Erudition powerhouses
                  like Jade and The Herta. On top of this the new Remembrance MC
                  is scoring unbelievably well and is in direct competition (you
                  can literally only run one or the other) with Harmony MC and
                  most are preferring RMC. It’s due to these reasons, we’re
                  following through on our watchlist from last time and lowering
                  their tier by one.
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="acheron" enablePopover />{' '}
                  added to watchlist (again). Acheron was watchlisted last time
                  to move up due to her outstanding performance - this time
                  we're listing her to move down instead. Last phase Acheron
                  dominated, having one of her strongest performances to date,
                  while this phase she has performed significantly worse,
                  especially if you don't have Jiaoqiu. But even with him, this
                  phase Acheron struggled quite a lot.
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="rappa" enablePopover />{' '}
                  added to watchlist (again). Rappa like Jing Yuan is a
                  character we marked with the watchlist to move down in the
                  last update. This update we’re renewing her watchlist -
                  something we try almost never to do. We're doing this because:
                </li>
                <ul>
                  <li>
                    Rappa, as part of the Break archetype, is one of the most
                    notorious benefactors of “sustainless” teams. The difference
                    between running 3 strong supports and Rappa and 2 supports
                    plus a sustain is often the difference between a 40,000
                    maxed score and barely scraping by on 30,000. Especially
                    when the phase isn’t Fire weak for Lingsha to exploit.
                    Rappa’s 2nd most played team composition, and highest
                    scorer, is “sustainless”.
                  </li>
                  <li>
                    Unlike many other DPS characters, Rappa doesn’t need to use
                    Tribbie and still sees tremendous success without her on the
                    team. Where the top 5-6 scoring teams of most DPS include
                    Tribbie, Rappa’s does not.
                  </li>
                  <li>
                    When either the enemy isn’t Imaginary weak or the True Grit
                    buff isn’t usable (or both) Rappa suffers. We saw the
                    perfect storm of this occur in 3.0. This is definitely a
                    demerit for Rappa compared to some of her peers who care
                    significantly less for enemy weaknesses and buffs.
                  </li>
                </ul>
                <li>
                  With all three of these things in mind, we have a character
                  that is not only very volatile between phases but also between
                  players. The difference between a Rappa player who is willing
                  to run her sustainless and one who isn’t, is tremendous and
                  the difference in scoring for Rappa between phases in favor of
                  her and against is also staggering. Because of this, we have
                  decided to wait one more phase to decide Rappa’s fate.{' '}
                </li>
              </ul>
              <h6>
                <strong>19/03/2025</strong>
              </h6>
              <p>
                We have just added{' '}
                <HSRCharacter mode="inline" slug="mydei" enablePopover /> to the
                tier list this time. Mydei has limited control over where he can
                direct his attacks and because of this, in target 'sensitive'
                encounters, he can be at a disadvantage. We've placed Mydei
                conservatively in modes that have these encounters currently,
                specifically in Apocalyptic Shadow.
              </p>
              <p>
                Pure Fiction tier list is the next to be updated (as a new phase
                just started) and you can expect it to happen next week.
              </p>
              <h6>
                <strong>13/03/2025</strong>
              </h6>
              <p>
                This Apocalyptic Shadow Tier list update is a smaller one. We
                focused on adjusting the ratings of recently released characters
                to better reflect how they fit into the meta, alongside making a
                few other changes.{' '}
              </p>
              <p>
                While this update is a relatively minor one, you can{' '}
                <strong>
                  expect major changes either next phase or the one after,
                  specifically focused on the Damage Category
                </strong>
                . The reason for this is the overabundance of characters
                residing in the Apex Tier for this mode. If everyone Apex, no
                one is. Apocalyptic Shadow is a game mode with a much wider
                variety of encounters than the other two and this variety allows
                for more characters to have stand out performances over a
                collection of phases. This is the reason so many characters
                reside in the Apex Tier currently.
              </p>
              <p>
                With that said, based on the upcoming character release schedule
                and drip marketing, we’re confident that the upcoming characters
                have a good shot at raising - or exceeding - the current Apex
                power level, continuing the dominance every 3.0 character has
                displayed so far.{' '}
              </p>
              <p>Upcoming characters:</p>
              <ul>
                <li>
                  <HSRCharacter mode="inline" slug="mydei" enablePopover /> a
                  Destruction character releasing incredibly soon,
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="castorice" enablePopover />{' '}
                  a Remembrance character releasing in 3.2 who is expected to be
                  a damage dealer,
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="anaxa" enablePopover /> who
                  also releases in 3.2 and is an Erudition character who could
                  be a strong standalone Erudition DPS or work well as a support
                  damage dealer for The Herta (or both).
                </li>
              </ul>
              <p>
                Going by the track record of 3.0, none of these characters
                should be weak. By waiting for more of the 3.0 cast to release,
                we’re confident it’ll be much clearer which characters should
                remain in Apex and which should not.
              </p>
              <p>
                While we’re waiting to address the “too many Apex Characters”
                problem, we did some smaller changes. Here are the characters
                receiving adjustments this time:
              </p>
              <ul>
                <li>
                  <HSRCharacter mode="inline" slug="the-herta" enablePopover />{' '}
                  T0.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T0. The Herta has dominated every phase of every mode in
                  endgame since the time of her release. We initially assigned
                  her a somewhat conservative placement in Apocalyptic Shadow,
                  but she’s proven multiple times now that she has what it takes
                  to stand at the top. While this is the case for now, it should
                  be noted that current phases are heavily tailored towards her
                  kit, element and playstyle.
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="aglaea" enablePopover /> T1{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T0.5. Aglaea like The Herta has also has shown she’s far more
                  capable in Apocalyptic shadow than expected, continuing the
                  trend of 3.0 dominance. Aglaea isn’t quite as popular a choice
                  nor as easy to play as her peers but she is certainly capable
                  of achieving outstanding scores in the mode (some of the
                  highest) when played right. For this reason we’re raising her
                  into the Apex Category.
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="kafka" enablePopover />{' '}
                  <HSRCharacter mode="inline" slug="black-swan" enablePopover />{' '}
                  <HSRCharacter mode="inline" slug="dr-ratio" enablePopover />{' '}
                  <HSRCharacter mode="inline" slug="clara" enablePopover /> have
                  not been performing at a level we associate with the tiers
                  they’re currently placed in and the other characters that
                  reside there. For this reason we’re moving them down.
                </li>
                <li>
                  <HSRCharacter
                    mode="inline"
                    slug="trailblazer-remembrance"
                    enablePopover
                  />{' '}
                  added to Watchlist. Trailblazer Remembrance has displayed
                  incredible performances in all three modes and AS is no
                  exception. While predominantly played with The Herta, they’re
                  also a top pick for sustainless compositions which are rising
                  in popularity and a decent option for Aglaea teams. This
                  combined with the suite of potential damage dealers coming
                  down the line, all of which are expected to be playable with
                  Trailblazer Remembrance, we’re watchlisting them to move to
                  T0.
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="firefly" enablePopover />{' '}
                  added to Watchlist. Firefly had a tougher time this phase as
                  she struggles against the featured bosses that require AoE to
                  deal with and that feature respawning trash enemies her
                  Technique can’t easily implant Fire weakness into. As one of
                  the most played damage dealers in AS, and a long standing
                  powerhouse, we’re not dropping her just yet, but keeping an
                  eye on if she can bounce back - since this was the first phase
                  in a long time where she struggled so much. Though, if she
                  won't recover, she will drop from T0 next update.
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="topaz" enablePopover />{' '}
                  added to Watchlist. Topaz has been performing notably worse
                  than March 7th (Hunt) in Apocalyptic Shadow encounters that
                  involve frequent target swapping, invulnerability phases (that
                  force target swapping) or AoE encounters of which as of late
                  there have been a lot of. The reason Topaz falls behind in
                  these scenarios is that her kit revolves around a single
                  target mark which takes time to move while March revolves
                  around a buff she never has to move. Ultimately, this means
                  March’s kit is almost always fully online while Topaz’s can
                  have downtime. While March 7th (Hunt) is pulling ahead in
                  recent phases we’re just watch listing Topaz for now.
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="seele" enablePopover />{' '}
                  added to Watchlist. Seele displayed an amazing performance
                  this phase, albeit whilst only being used by very few players.
                  We’re placing a watch list on her to see if she can pull off
                  another convincing performance over the next 2 phases to climb
                  herself out of the niche category of tiers.
                </li>
              </ul>
              <h6>
                <strong>26/02/2025</strong>
              </h6>
              <ul>
                <li>
                  <HSRCharacter mode="inline" slug="tribbie" enablePopover />{' '}
                  has been added to the tier list to the Support DPS category.
                  She could be placed in Amplifier category too, but compared to
                  other characters there she focuses on dealing damage first
                  while her supportive abilities are secondary.
                </li>
                <li>
                  No other changes to the tier list have been made - her impact
                  on other characters will be included in next tier list update
                  once we gather the next set of data in all 3 modes.
                </li>
              </ul>
              <h6>
                <strong>23/02/2025</strong>
              </h6>
              <p>
                This update will address the latest phase of Memory of Chaos,
                the increasing difficulty associated with the mode, but also the
                shift in meta (caused by new enemies and buffs introduced
                recently).
              </p>
              <p>
                Memory of Chaos continues to become more challenging with each
                phase but 3.0’s MoC is particularly brutal. The difficulty
                increases are the result of (but not limited to):
              </p>
              <ul>
                <li>
                  Higher enemy damage numbers and more lethal battle scenarios,
                </li>
                <li>Higher HP of the enemies,</li>
                <li>New and much harder enemies types and mechanics,</li>
                <li>
                  Encounters and buffs specifically tailored towards new
                  characters (and in some cases against old characters),
                </li>
                <li>
                  A need to make the game harder to justify the higher power
                  level of new characters (powercreep).
                </li>
              </ul>
              <p>
                We base our tier list on the 3 most recent phases, and MoC 2.7
                served as an introduction to a heavier AoE-focus and increase in
                difficulty, but 3.0 doubles down on this - with a specific push
                via the buff tailored toward new characters. The result of this
                is two thirds of the phases influencing the tier list heavily,
                favoring new characters and leading to large shifts in
                categories containing them (mostly the DPS).
              </p>
              <p>Tier list changes:</p>
              <ul>
                <li>
                  <HSRCharacter mode="inline" slug="aglaea" enablePopover />{' '}
                  T0.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T0. We placed Aglaea conservatively on our tier list in order
                  to observe how the community would receive her more
                  restrictive than average requirements. Aglaea smashed 3.0’s
                  MoC which was custom made for her but also performed great in
                  2.7’s MoC which wasn’t specifically made with her in mind (and
                  she was the fastest there too). We feel she deserves to sit in
                  Tier 0.
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="qingque" enablePopover /> T4{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T3. The little gambling gremlin actually thrives in the most
                  recent phases so we're raising her up a tier.
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="firefly" enablePopover /> &{' '}
                  <HSRCharacter mode="inline" slug="feixiao" enablePopover /> T0{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T0.5. Just like in the transition between 1.x and 2.x, as we
                  move from 2.x to 3.x, we’re seeing an increase in power for
                  new characters, new enemies/mechanics that favor them and a
                  fall off of older characters. Feixiao and Firefly are two of
                  the high profile casualties of this. Both characters are still
                  absolutely strong but Feixiao is at a disadvantage in the
                  AoE-focused phases and Firefly, while technically a Blast
                  damage dealer, doesn’t thrive in AoE either and has particular
                  trouble with bosses that spawn multiple enemies frequently
                  that aren’t weak to Fire (a common occurrence of late). The
                  combination of Feixiao and Firefly losing their usual edge due
                  to unfavorable conditions, and The Herta and Aglaea getting
                  exactly what they need to succeed is why we are reshuffling
                  the highest tier.
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="boothill" enablePopover />{' '}
                  T0.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T1. Boothill is currently at a severe disadvantage in the
                  latest MoC phases, being a single target character against
                  predominantly AoE encounters. Until the focus returns to
                  single target, we’re lowering his rating for now.
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="yunli" enablePopover /> T0.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T1. Yunli is currently not performing on par with other Apex
                  rated damage dealers especially considering the adjustment in
                  rating to Firefly and Feixiao. We had Yunli on the edge of the
                  Apex tiers before but with the adjustment to other characters
                  ratings and the higher requirements of the mode, we’re
                  lowering her rating for now.
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="clara" enablePopover /> T1.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T2. Similar to Yunli, Clara is struggling in the recent phases
                  as they're not as tailored to her gameplay style as the
                  previous ones.
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="blade" enablePopover /> T2{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T3. Maybe Blade finally received what he dreamt of - a
                  glorious death. But kidding aside, time wasn't kind to our
                  berserker and he's one of the worst performing limited
                  characters in the last 3 phases, so we're dropping him out of
                  the Meta tier.
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="tingyun" enablePopover /> T1{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T1.5. Tingyun’s niche as an Energy battery has been usurped by
                  Sunday and her amplification abilities have been powercrept,
                  resulting in low play rates, lower scores and no best in slot
                  teams in the meta. Tingyun is still perfectly usable and is a
                  2nd, 3rd or 4th pick for a lot of characters but as a result
                  of the above we’re lowering her rating.
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="asta" enablePopover /> T2{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T3. She's simply not used anymore - being 3rd or even 4th pick
                  in various teams as the limited Amplifiers just offer more.
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="hanya" enablePopover /> T3{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T4. Same reasoning as with Asta.
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="fu-xuan" enablePopover />{' '}
                  T0.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T1. Compared to Gallagher, Lingsha, Aventurine and Huohuo, Fu
                  Xuan hasn’t aged quite as well. Her kit lacks the synergy of
                  the aforementioned characters, and her sustain is less
                  flexible in the face of the harder content we’re experiencing
                  (read: she explodes too often from strong AOE attacks and you
                  can't prevent it). Fu Xuan still maintains competitive clear
                  times and high usage for now, but measured up to characters
                  like Huohuo who have direct synergies with newly released
                  units like Aglaea and The Herta via her energy gain, she
                  doesn’t quite have the same edge.
                </li>
              </ul>
              <p>
                New <strong>Watchlist</strong> additions:
              </p>
              <ul>
                <li>
                  <HSRCharacter mode="inline" slug="kafka" enablePopover /> &{' '}
                  <HSRCharacter mode="inline" slug="black-swan" enablePopover />{' '}
                  - The rapidly increasing difficulty of MoC has been rough on a
                  lot of characters, but the DoT archetype is one suffering more
                  than most. Black Swan, Kafka (and by extension the DoT
                  archetype) have not done well in the past two phases, so we’re
                  placing both characters on the watch list but expect them to
                  move down barring any drastic changes in 3.1’s phase.
                </li>
              </ul>
              <p>
                New feature - <strong>Double Placement</strong>!
              </p>
              <p>
                We have revamped the tier list backend which allows us now to
                place characters in multiple 'columns' at the same time. This
                change was caused by{' '}
                <HSRCharacter mode="inline" slug="argenti" enablePopover /> who
                in the most recent MoC was mostly paired with{' '}
                <HSRCharacter mode="inline" slug="the-herta" enablePopover />{' '}
                and his hypercarry teams were in the minority (91% of his teams
                paired him with the Witch). So now you can check his ratings
                when he's used as the Witch slave and when he's the one doing
                the heavy lifting (and also his Support DPS version received the
                Partner tag).
              </p>
              <p>
                We've placed the new Support DPS Argenti in T1 for now, but he's
                close to reaching the Apex tier - pending the next phase
                results.
              </p>
              <p>
                Do let us know what you think of this change and who else do you
                think should get it!
              </p>
              <h6>
                <strong>15/02/2025</strong>
              </h6>
              <p>
                Today we're making big adjustments to the Pure Fiction tier
                list. The rework of this mode is still relatively fresh and
                compared to last phase this one is was substantially more
                difficult to clear. As a result of this, expect more changes in
                the near future but also a number of characters to be placed on
                the watchlist instead of being moved right away.{' '}
                <strong>
                  We also brought back the character usage over time section in
                  the PF Analytics
                </strong>{' '}
                that showcases last 2 phases average data.
              </p>
              <p>Now, let's to go over all the changes.</p>
              <p>
                The first thing we want to tackle is the Break/Superbreak
                archetype that had a pretty rough time since the rework. Caused
                by the rework itself, but also the lack of Fire and Imaginary
                weakness and lackluster buffs for the archetype. We don't know
                if the trend will continue with the buffs favoring other
                archetypes during the 3.x patch, but for now the experience
                playing Break teams is pretty miserable.
              </p>
              <ul>
                <li>
                  <HSRCharacter mode="inline" slug="firefly" enablePopover /> T2{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T3. Firefly is the biggest loser among the Break archetype
                  under the new Pure Fiction rule set. Firefly was never
                  outstanding in the mode but she was able to carve herself out
                  a niche thanks to her reasonable blast damage but more
                  importantly frequent Fire Weakness implant. In the new Pure
                  Fiction, her blast is severely diminished and her Fire Implant
                  potential has been slashed: Firefly is only able to implant
                  the very first enemies of each wave with her Technique - all
                  subsequent enemies that spawn (which is a lot) are unaffected.
                  The result of this is less effective damage for Firefly,
                  dramatically reduced effectiveness against non-Fire weak
                  enemies and a drop from the “meta” tiers.
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="himeko" enablePopover /> T1{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T1.5. While not really a pure Break character, Himeko is
                  commonly played with Firefly (or instead of her) so it's not
                  surprise, she's also affected by the rework in a negative way.
                  While she can be used with The Herta as her Erudition partner,
                  other characters like Herta, Serval or Argenti are picked over
                  her. We will have to wait what direction Himeko will go next,
                  but for now we're lowering her half a tier.
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="rappa" enablePopover />{' '}
                  (Added to watchlist) - Compared to Firefly, Rappa has suffered
                  less but is still clearly hurting from the lack of a good
                  themed buff and a side containing Imaginary weak enemies. With
                  absolutely nothing going Rappa’s way we’re placing her on the
                  watchlist but are giving her one more phase to recover.
                </li>
                <li>
                  <HSRCharacter
                    mode="inline"
                    slug="trailblazer-harmony"
                    enablePopover
                  />{' '}
                  (Added to watchlist) - With Firefly six feet under and Rappa
                  catching strays, it's no surprise than Harmony Trailblazer’s
                  play rate and performance have both suffered as a result. With
                  both this characters primary teammates on the chopping block,
                  alongside the meteoric rise of Remembrance Trailblazer (a
                  mutually exclusive competitor) we’re placing Harmony
                  Trailblazer on the watchlist to move down.
                </li>
              </ul>
              <p>
                Now let's talk about the DPS and Sub-DPS belonging to other
                archetypes.
              </p>
              <ul>
                <li>
                  <HSRCharacter mode="inline" slug="aglaea" enablePopover /> T2{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T1.5. Aglaea has performed better than anticipated albeit with
                  low representation overall (below 2%). We see the potential
                  some players have demonstrated with Aglaea in the mode and if
                  she continues to perform/improve we may raise her even further
                  next phase.
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="yunli" enablePopover /> T1{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T1.5. Yunli, what happened to you? Despite this phase
                  featuring a lot Phys-weak enemies, Yunli's performance is just
                  terrible. It looks like post-rework, her counter-gameplay is
                  just too slow for the mode where you need to kill things as
                  fast as possible and we're dropping her because of that.
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="clara" enablePopover /> T2{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T3. Same reasoning as Yunli - Clara's archetype is just too
                  slow for the new Pure Fiction.
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="feixiao" enablePopover /> T2{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T3. The mode rework hit her badly. Previously she could be
                  paired with Herta and be used as enabler for her, but it's no
                  longer the case in the new format and her performance dropped.
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="jingliu" enablePopover /> T3{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T4. With some characters dropping to T3, they pushed Jingliu
                  further down. She's still struggling and hopefully will get
                  some love from the devs soon.
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="topaz" enablePopover /> &{' '}
                  <HSRCharacter
                    mode="inline"
                    slug="march-7th-swordmaster"
                    enablePopover
                  />{' '}
                  T3{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T4. Single-target characters struggle too much post rework and
                  we're dropping both Topaz and Stabby March because of that as
                  they are closed to the Forgotten Ones than Meta characters.
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="sampo" enablePopover /> T4{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T5. DoT as archetype is struggling but while Kafka and Black
                  Swan still have some uses, Sampo doesn't.
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="acheron" enablePopover />{' '}
                  (Added to watchlist) - Acheron paired with Jiaoqiu has
                  produced back to back dominating performances, rivaling even
                  the freshly released The Herta. With that said, both phases
                  featured Lightning Weakness and this phase features enemies
                  that apply debuffs on death, boosting her performance. For now
                  we’re placing her on the watchlist to move to T0 if she
                  maintains pace or better yet improves next phase.
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="jing-yuan" enablePopover />{' '}
                  (Added to watchlist) - While Aglaea surprised us in a good
                  way, Jing Yuan did the opposite. He had everything going for
                  him this phase (Weakness, Grit mechanic, buffs) and yet in
                  both our data and in the hands of our testers, he didn't
                  perform at the level he should. Because of this we're placing
                  him on watchlist to see how he performs in the next phase.
                </li>
              </ul>
              <p>Finally, let's talk about the Amplifiers.</p>
              <p>
                Before this update, we placed all top Amplifiers in T0.5
                together to see who would come out on top once the meta in the
                new PF settled. With the exceptional entrance of Remembrance
                Trailblazer, the current struggle of Break and the need to make
                a move and prevent a traffic jam of 6 of the best characters
                residing in T0.5, we’ve decided to follow through with some
                adjustments - even if we will need to modify them in the event
                Break recovers or other characters falter.
              </p>
              <ul>
                <li>
                  <HSRCharacter
                    mode="inline"
                    slug="trailblazer-remembrance"
                    enablePopover
                  />{' '}
                  T1{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T0. Remembrance Trailblazer’s debut performance in Pure
                  Fiction has been impressive to say the least, being one of the
                  go-to supports for The Herta teams. While Remembrance
                  Trailblazer certainly saw the most play and best scores with
                  The Herta, that’s not the only team in which they proved to be
                  viable in. As one of the top choice of one of the best damage
                  dealers, a huge play rate and one of the best average scores
                  alongside the potential to be played in other comps we feel
                  Remembrance Trailblazer has enough on their resume to be
                  placed in T0 for now.
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="robin" enablePopover /> T0.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T0. Robin is one of the most versatile Amplifiers in the game
                  - being able to play with virtually any non-Break DPS and
                  improve the team's performance. Full team action advance is
                  just too broken.
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="sunday" enablePopover />{' '}
                  T0.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T0. Similar to Remembrance Trailblazer, Sunday has found a
                  place in The Herta teams but also is one of the top picks in
                  other high meta and apex damage dealers teams. Sunday has a
                  lower play rate, score and struggles with skill points at
                  times in some teams, but we feel his flexibility and current
                  performance in the reworked PF is enough to set him apart from
                  those in T0.5.
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="asta" enablePopover /> T3{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T4. Asta isn't having a good time nowadays as she's usually
                  the last pick in teams where she could be used, so we're
                  dropping her down a tier.
                </li>
                <li>
                  We also want to mention{' '}
                  <HSRCharacter mode="inline" slug="jiaoqiu" enablePopover />.
                  Ever since his release, Jiaoqiu has performed exceptionally
                  well in Pure Fiction achieving some of the highest scores each
                  phase. When considering who to place in T0 in this update his
                  placement was discussed heavily. Ultimately we feel he isn’t
                  at a T0 level - the reason is that he is essentially chained
                  to Acheron. He is the character closest to being a single-use
                  character that simply has no other uses in the meta outside of
                  being Acheron slave, and that's not enough to place him in T0
                  - especially if she's not T0 either.
                </li>
              </ul>
              <p>We also have added Partner tag to two new characters:</p>
              <ul>
                <li>
                  <HSRCharacter mode="inline" slug="jiaoqiu" enablePopover />{' '}
                  who relies on{' '}
                  <HSRCharacter mode="inline" slug="acheron" enablePopover />{' '}
                  (95-97% of JQ teams in our data use Acheron)
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="serval" enablePopover /> who
                  relies on{' '}
                  <HSRCharacter mode="inline" slug="the-herta" enablePopover />
                </li>
              </ul>
              <p>
                Why <HSRCharacter mode="inline" slug="firefly" enablePopover />{' '}
                and <HSRCharacter mode="inline" slug="feixiao" enablePopover />{' '}
                don't have the tag? Firefly can pick two out of Ruan Mei, Fugue
                and HMC, so she's not tied to one specific character (which is
                the definition of the tag) and Feixiao isn't as reliant on Robin
                anymore as now she can use Sunday, RMC or even Bronya and still
                perform within the tier.
              </p>
              <p>
                Also, new MoC phase starts in 2 days, so we decided to just wait
                and update the tier list for this mode once we gather data for
                it and test the new characters there.
              </p>
              <h6>
                <strong>05/02/2025</strong>
              </h6>
              <p>
                With the release of{' '}
                <HSRCharacter mode="inline" slug="aglaea" enablePopover /> we’re
                adding a new tag - the Partner Tag - placing Aglaea on the tier
                list, updating Sunday’s ratings and addressing the current state
                of flux the endgame has found itself in post 3.0 world.
              </p>
              <p>
                As we’ve flagged in our previous updates, all three modes are
                undergoing a shift in meta toward a more AoE focus (well, PF was
                already AoE-focused, but now it's AoE-focus+). We’re fully aware
                that some characters are rated too high - specifically
                characters with a single target focus or who don’t match up well
                against new enemy mechanics or vulnerabilities. With that said,
                we want to avoid repositioning a character incorrectly. To
                ensure this doesn’t happen, we’re waiting for the next phase of
                Pure Fiction (currently underway) and Memory of Chaos (coming
                soon) to make major changes.
              </p>
              <p>
                That's why we have only added Aglaea to the list and adjusted
                the position of a single character, majorly affected by her
                release:
              </p>
              <ul>
                <li>
                  <HSRCharacter mode="inline" slug="aglaea" enablePopover /> -
                  Under the right conditions, in the right team, with the
                  correct build and Speed-tuning, Aglaea can be - and is - one
                  of the strongest damage dealers in the game when it comes to
                  raw numbers, making her quite a threat specifically in her
                  best endgame mode - Memory of Chaos. With that said, similar
                  to past character releases, we’ve decided to commit to a
                  conservative placement for her initially. We’re confident
                  Aglaea has what it takes to achieve a T0 ranking under ideal
                  conditions but we want to see how she’ll perform in the hands
                  of the community, with a variety of different investment,
                  relic quality, team and tuning levels. Like with The Herta,
                  you can expect an update on the state of Aglaea shortly after
                  her release.
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="sunday" enablePopover /> -
                  With Aglaea, Sunday gains yet another top tier dps partner,
                  specifically in MoC. As a character that is mandatory for some
                  and a top choice for most others in MoC, we’re moving him up
                  to Tier 0.
                </li>
              </ul>
              <p>And here's the definition of the new tag:</p>
              <p>
                <strong>Partner</strong> - characters with the Partner tag are
                incredibly reliant on being paired with one other specific
                character in order to function at the highest level. Characters
                marked with this tag will perform one - but sometimes even more
                tiers - lower than their listed position on the tier list
                without those characters alongside them.
              </p>
              <p>
                We’re adding this tag to address and flag the weakness some
                characters have when played without their ideal “partner”.
                Characters we currently feel that fall under this category are:
              </p>
              <ul>
                <li>
                  <HSRCharacter mode="inline" slug="acheron" enablePopover />{' '}
                  who relies on{' '}
                  <HSRCharacter mode="inline" slug="jiaoqiu" enablePopover />
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="aglaea" enablePopover /> who
                  relies on{' '}
                  <HSRCharacter mode="inline" slug="sunday" enablePopover />
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="jing-yuan" enablePopover />{' '}
                  who also relies on{' '}
                  <HSRCharacter mode="inline" slug="sunday" enablePopover />
                </li>
              </ul>
              <p>
                Let us know how you feel about the Partner tag and if you think
                additional characters should be tagged with it (just remember,
                for the Partner tag to apply, the next best alternative causes
                the character to drop a rank or more when it comes to their
                performance).
              </p>
              <h6>
                <strong>25/01/2025</strong>
              </h6>
              <p>
                The dust after the 3.0 release has settled and with it came the
                release of a new phase of Apocalyptic Shadow. In this update
                we’re addressing the feedback to our major rework to all tier
                lists, the release of The Herta and Trailblazer Remembrance, and
                the meta shifts caused by Apocalyptic Shadow changes to be more
                AoE-focused.
              </p>
              <p>
                <HSRCharacter mode="inline" slug="the-herta" enablePopover />{' '}
                (and most Erudition characters that work with her) and{' '}
                <HSRCharacter
                  mode="inline"
                  slug="trailblazer-remembrance"
                  enablePopover
                />{' '}
                have all exceeded their initial more conservative rankings on
                the tier list. As a result{' '}
                <HSRCharacter mode="inline" slug="serval" enablePopover />{' '}
                <HSRCharacter mode="inline" slug="herta" enablePopover />{' '}
                <HSRCharacter mode="inline" slug="jade" enablePopover />{' '}
                <HSRCharacter mode="inline" slug="argenti" enablePopover />{' '}
                <HSRCharacter
                  mode="inline"
                  slug="trailblazer-remembrance"
                  enablePopover
                />{' '}
                improved their ratings.
              </p>
              <p>
                Based on feedback we’re also making adjustments to the ranking
                of Support DPS in the Follow-Up ATK archetype to better convey
                the difference in power, usability and flexibility between{' '}
                <HSRCharacter mode="inline" slug="topaz" enablePopover />{' '}
                <HSRCharacter
                  mode="inline"
                  slug="march-7th-swordmaster"
                  enablePopover
                />{' '}
                and <HSRCharacter mode="inline" slug="moze" enablePopover />.
                The difference between Topaz and March 7th Hunt isn’t large
                enough to maintain a full tier difference between the two -
                especially when that Tier is Tier 0 - whilst Moze is more
                situational than both of them.
              </p>
              <p>
                Apocalyptic Shadow includes changes caused by The Herta's
                release and the change to the mode itself that become apparent
                in the new mode - AOE-focus (or rather, AoE-shilling). As
                mentioned in our previous update, the meta is undergoing a large
                shift towards heavy AoE Focus (5 targets) - and AS is the first
                to receive the brunt of this shift. The result of this is a
                decline in the viability of Single Target focused characters
                with an improvement for AoE Focused characters. We’re being
                conservative in our changes for now, to see how players, teams
                and encounters evolve in future phases but here are the AS
                specific changes being made for now.
              </p>
              <ul>
                <li>
                  <HSRCharacter mode="inline" slug="boothill" enablePopover />{' '}
                  T0{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T0.5. Boothill, a man who specialises in single target
                  Toughness bar destruction, is at a disadvantage when it comes
                  to the new AoE-focused encounters in the latest Phases of AS.
                  It should come as no surprise he can’t quite duke it out with
                  characters with kits designed specifically to counter the
                  bosses we’re up against. He’s moving down a tier for now but
                  will likely be back when Single Target returns.
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="dr-ratio" enablePopover />{' '}
                  T1{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T1.5. Like Boothill, Dr. Ratio is similarly struggling with
                  being single target and reliant on debuffs to function.
                  Keeping constantly respawning enemies debuffed enough for
                  Ratio to trigger his Follow-Up attacks, on top of having to
                  deal with them one by one is a huge penalty compared to his
                  competition. Ratio is struggling to keep pace with his peers,
                  we’re lowering him a tier for now.
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="argenti" enablePopover /> T2{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T1.5. The AoE changes, introduction of The Herta and a new
                  favorable AS boss have all been huge for Argenti and it shows.
                  We’re raising Argenti up a tier as a result of this.
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="the-herta" enablePopover />{' '}
                  (Watchlist): The Herta has produced smashing results in the
                  Apocalyptic Shadow with a boss which for all intents and
                  purposes was designed for her by achieving some of the best
                  scores. While her performance this phase is undoubtedly Tier 0
                  worthy we want to see how she performs against a boss not so
                  specifically tailored toward her strengths. As a result we’re
                  watchlisting her for now.
                </li>
              </ul>
              <p>Big thanks for all the feedback provided!</p>
              <h6>
                <strong>15/01/2025</strong>
              </h6>
              <p>
                With the release of the 3.0 update we’re implementing a change
                we’ve been intending on making, and which has been much
                requested for many months now - the shift to having all primary
                damage dealers be rated in the same category.
              </p>
              <p>
                This entails moving all characters we feel capable of serving as
                a “Main Damage Dealer” to the DPS Category, most of which are
                shifting from the Specialist Category. Here are the character’s
                moving:
              </p>
              <ul>
                <li>
                  <HSRCharacter mode="inline" slug="boothill" enablePopover />
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="firefly" enablePopover />
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="rappa" enablePopover />
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="sushang" enablePopover />
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="xueyi" enablePopover />
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="luka" enablePopover />
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="kafka" enablePopover />
                </li>
              </ul>
              <p>
                With the exception of Luka and Kafka (we’ll come back to them
                soon) we feel each of these characters are far more accurately
                represented in their new home.
              </p>
              <p>
                In addition to the re-ordering above, we’re also renaming the
                Specialist Category to something else. The Specialist Category
                was initially intended as a home for all characters that didn’t
                fit the traditional damage mold. It was the solution to
                characters that didn’t just deal raw damage but also offered
                something else. It was the home of Breakers, Hybrid
                Support-Damage dealers and highly synergistic characters that
                didn’t function well alone. Over time these characters, and
                mechanics have evolved, became more accessible and all operate
                more closely to traditional damage dealers.
              </p>
              <p>
                All this led to the decision to not only recategorize some
                characters but also repurpose the Specialist category into
                something more relevant toward how the game and meta plays
                today. We’re repurposing the Specialist Category into the
                Support DPS Category.
              </p>
              <ul>
                <li>
                  <strong>Support DPS</strong>: Characters that fall within this
                  category are very rarely played as the primary or sole damage
                  dealer of the team and instead almost always play alongside
                  another character that fills that role instead. Support damage
                  dealers are still built with the intention to deal damage
                  themselves but also have a kit containing buffs/debuffs to
                  bolster their team's performance and or have abilities that
                  are directly dependent on others in order to function.
                </li>
              </ul>
              <p>
                As the meta has developed we’ve seen an increase in characters
                that are half way between damage and support as well as
                characters that can deal good amounts of damage but do so in a
                far more supportive role.
              </p>
              <p>
                With the release of The Herta we’re moving the category of two
                erudition characters to better reflect their role in the current
                meta as Support DPS:
              </p>
              <ul>
                <li>
                  <HSRCharacter mode="inline" slug="herta" enablePopover /> or
                  “Mini Herta” is already played primarily as a “Sub-DPS” in
                  modes such as Pure Fiction and Apocalyptic shadow, serving as
                  an incredible partner to almost all other characters, thanks
                  to her Follow-Up spins which she relies on her teammates to
                  help trigger. With the release of The Herta, she gains even
                  more dual DPS synergy as she can satisfy the Erudition
                  requirement The Herta seeks to fulfill, giving her Support DPS
                  potential even in modes she wasn’t great in previously like
                  MoC.
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="serval" enablePopover /> -
                  Prior to The Herta’s release, Serval hasn’t seen much play or
                  meta relevance but now she can operate as one of the better
                  Erudition supports for The Herta. Playing Serval normally is
                  good enough but she can also double down on a far more
                  supportive build involving maximum energy regeneration
                  solidifying her for us as a Support DPS for now.
                </li>
              </ul>
              <p>
                As for{' '}
                <HSRCharacter mode="inline" slug="lingsha" enablePopover />{' '}
                she's a Healer that loves killing and she honestly could be
                placed in Support DPS too. Still, she's a Healer first so we
                decided to don't do it, yet. One option we're considering is
                placing Lingsha in two categories at the same time and rate her
                by different criteria - let us know what you think about this
                idea.{' '}
              </p>
              <p>
                Finally coming back to{' '}
                <HSRCharacter mode="inline" slug="luka" enablePopover /> and{' '}
                <HSRCharacter mode="inline" slug="kafka" enablePopover />. These
                characters are two we feel could belong in both categories. It
                could be argued that Kafka only truly functions with another DoT
                character on the team, due to her DoT triggering nature - it can
                also be argued that Luka is more effective when played alongside
                another damage dealer, serving as a break finisher instead of
                the sole contributor. Right now we’re moving forward with both
                as Main DPS - we feel Kafka is usually the driving force of DoT
                teams she’s placed in and therefore is the main contributor and
                in a similar boat when Luka is played he’s usually the one
                outputting a large portion of the teams damage thanks to his
                giant Physical Break damage.
              </p>
              <p>
                With so many changes you can treat this update as a rework to
                the tier list. Also, we expect a large meta shake-up over the
                course of 3.x,{' '}
                <strong>
                  especially because all modes started morphing into being more
                  AoE-focused
                </strong>{' '}
                - this trend started with Rappa release and got even bigger with
                The Herta. We will have to wait and see how long it will last,
                but at least for now, single-target focused characters are at a
                big disadvantage (with the exception of Feixiao). And as always
                we’re always listening to feedback so let us know what you
                think.
              </p>
              <h6>
                <strong>05/01/2025</strong>
              </h6>
              <p>
                This tier list update will be quite unique due a few reasons.
                First one is the revamp of the Pure Fiction mode and the second
                is the upcoming 3.0 full tier list revamp. We will go over both
                in the changelog.
              </p>
              <p>
                <strong>Pure Fiction rework</strong>
              </p>
              <p>
                Pure Fiction has received a rework. The changes to the mode
                include:
              </p>
              <ul>
                <li>
                  The introduction of the Grit mechanic, replacing the old buff
                  mechanic of Pure Fiction (buffs are more generic in nature so
                  far),
                </li>
                <li>
                  Constantly respawning non-boss enemies, resulting in the field
                  always having 5 enemies at a time (much heavier AoE focus),
                </li>
                <li>
                  Boss enemies now always take damage when any non-elite enemy
                  dies (even more focus on AoE).
                </li>
              </ul>
              <p>
                After testing and comparing our results against the first set of
                data under PF’s new rules, here is the general theme of the
                changes to the meta and tier list:
              </p>
              <ul>
                <li>
                  Characters capable of hitting 3-5 enemies frequently are more
                  powerful while characters with more singular focused damage
                  are weaker,
                </li>
                <li>
                  Characters with trigger on kill or conditional effects that
                  scale on number of enemies in the fight are stronger,
                </li>
                <li>
                  Supports, sustains and specialists that play into the kits of
                  any of the characters included in the categories above are
                  stronger.
                </li>
              </ul>
              <p>
                Before you look at the updated PF ratings, here are a few things
                to note:
              </p>
              <ul>
                <li>
                  Only one phase is currently available, one set of Grit buffs
                  and one set of enemies, limiting the amount of testing and
                  data that can be collected,
                </li>
                <li>
                  Compared to pre-revamp version of the mode, this current phase
                  is one of the easiest ever - both our testing and the data
                  show that on average, most characters can reach way higher
                  scores than before,
                </li>
                <li>
                  Erudition is heavily favored in this mode even more so than
                  before. The Herta is a new limited Erudition unit releasing in
                  3.0 and she looks to be very powerful - it’s likely she will
                  shake up the DPS category,
                </li>
                <li>
                  Apex Amplifier’s are in an unusual position right now. All of
                  Sunday, Fugue, Robin, Jiaoqiu and Ruan Mei have strong
                  arguments for being T0 - they each have a team that has shown
                  it can obliterate the reworked game mode and can work
                  independently of each other. While we feel some do have an
                  edge over others we’re going to wait for the release of The
                  Herta, another phase of Pure Fiction and more data before
                  committing to placing someone in T0.
                </li>
              </ul>
              <p>
                Because of all the above, there won't be a detailed changelog
                this time - it's more like a blank slate that is work in
                progress due to all the limitations and with the next phase we
                will know more about the direction Hoyo wants to take the mode
                in.
              </p>
              <p>
                <strong>Upcoming 3.0 Tier list revamp</strong>
              </p>
              <p>
                We already mentioned the revamp several times, but here's some
                more information about what we plan to change:
              </p>
              <ul>
                <li>
                  The current DPS and Specialist categories will be killed,
                </li>
                <ul>
                  <li>
                    Specialists category is a relic from the early days of HSR
                    where Break and other non-Crit archetypes were less popular
                    and we created it to distinct them from each other. But
                    nowadays, CRIT and non-CRIT DPS just fill the same role -
                    killing things. So it makes no sense to keep them split,
                  </li>
                </ul>
                <li>
                  A new category will be created - <strong>Main DPS</strong>.
                  Damage dealers of all archetypes - CRIT, Break, DoT, FUA -
                  will all be placed there. As the name suggests, those
                  characters are primarily used as the main damage source on the
                  team, no matter their archetype,
                </li>
                <li>
                  A new category will be created - <strong>Hybrid</strong>.
                  Hybrid categories are characters that on top of doing damage,
                  have some supportive abilities that help the team - either via
                  buffs or debuffs. What is important, they can do both decent
                  damage and support at the same time, without sacrificing
                  either. Pure supporters whose damage is negligible will still
                  remain in the Amplifier category. Here are a few examples of
                  characters who will end up in this category: Jade, Topaz,
                  Moze, and Black Swan (maybe even Lingsha, but she would be an
                  exception appearing in two categories at the same time).
                </li>
              </ul>
              <p>
                There will be A LOT of changes coming with that update,
                especially in the APEX category as we will have to change the
                criteria and pit the CRIT archetype against all the others.
                That's why we didn't make any drastic changes in the current
                Pure Fiction update (4x T0 DPS characters for example) as it's
                the last time things look like that.
              </p>
              <p>
                <strong>Other post-Fugue changes</strong>
              </p>
              <p>
                <HSRCharacter
                  mode="inline"
                  slug="tingyun-fugue"
                  enablePopover
                />{' '}
                has proven to be way stronger than we anticipated, so we have
                lowered Trailblazer Harmony ratings in MoC and AS to better
                showcase the pecking order when it comes to Super Break
                supports.
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <div className="fuse-ad-placeholder" data-fuse="22844297241"></div>
        {/* <div className="promo-top with-margin">
          <OutboundLink
            href="https://topup.bot/?game=hsr&utm_source=prydwen&utm_medium=referral&utm_campaign=january"
            target="_blank"
          >
            <StaticImage
              src="../../images/starrail/promo_tier.webp"
              alt="Toptup.bot Promotion"
              className="desktop"
            />
            <StaticImage
              src="../../images/starrail/promo_mobile.webp"
              alt="Toptup.bot Promotion"
              className="mobile"
            />
            <p className="mobile text">
              <strong>Topup.bot</strong> - Oneiric Shards at a 20% discount,
              delivered instantly to UID!
            </p>
          </OutboundLink>
        </div> */}
        <div className="tier-list-header">
          <p className="title">
            Tier List{' '}
            <span>
              (
              {(sortOption === 'moc' || sortOption === 'moc_special') &&
                'Memory of Chaos'}
              {(sortOption === 'pure' || sortOption === 'pure_special') &&
                'Pure Fiction'}
              {(sortOption === 'apo' || sortOption === 'apo_special') &&
                'Apocalyptic Shadow'}
              )
            </span>
          </p>
          <p className="sub-title">
            You're currently viewing the{' '}
            <strong>
              {(sortOption === 'moc' || sortOption === 'moc_special') &&
                'Memory of Chaos'}
              {(sortOption === 'pure' || sortOption === 'pure_special') &&
                'Pure Fiction'}
              {(sortOption === 'apo' || sortOption === 'apo_special') &&
                'Apocalyptic Shadow'}
            </strong>{' '}
            tier list. It shows{' '}
            {(sortOption === 'moc' || sortOption === 'moc_special') &&
              'how the character performs in the Memory of Chaos. Blast and single target damage are important here while AoE has niche uses against some bosses.'}
            {(sortOption === 'pure' || sortOption === 'pure_special') &&
              'how the character performs in the Pure Fiction. AoE is king here, followed by Blast while single target damage is mostly useless.'}
            {(sortOption === 'apo' || sortOption === 'apo_special') &&
              'how the character performs in the Apocalyptic Shadow. Single target and Break potential are heavily favored here.'}{' '}
            Use the switcher below to view a different tier list.
          </p>
        </div>
        <div className="eidolon-switcher">
          <div className="custom-switch">
            <Switch
              checked={eidolonSpecial}
              onChange={() => setEidolonSpecial(!eidolonSpecial)}
              onColor="#009EEC"
              offColor="#484950"
              className="switch"
            />
            Show 4★ characters at Eidolon 6 (E0 otherwise)
          </div>
        </div>
        {eidolonSpecial === true ? (
          <>
            <div className="tier-list-switcher">
              <div
                className={`option moc ${
                  (sortOption === 'moc' || sortOption === 'moc_special') &&
                  'selected'
                }`}
                onClick={() => setSortOption('moc')}
              >
                <h6>Memory of Chaos</h6>
              </div>
              <div
                className={`option pure ${
                  (sortOption === 'pure' || sortOption === 'pure_special') &&
                  'selected'
                }`}
                onClick={() => setSortOption('pure')}
              >
                <h6>Pure Fiction</h6>
              </div>
              <div
                className={`option apo ${
                  (sortOption === 'apo' || sortOption === 'apo_special') &&
                  'selected'
                }`}
                onClick={() => setSortOption('apo')}
              >
                <h6>Apocalyptic Shadow</h6>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="tier-list-switcher">
              <div
                className={`option moc ${
                  (sortOption === 'moc' || sortOption === 'moc_special') &&
                  'selected'
                }`}
                onClick={() => setSortOption('moc_special')}
              >
                <h6>Memory of Chaos</h6>
              </div>
              <div
                className={`option pure ${
                  (sortOption === 'pure' || sortOption === 'pure_special') &&
                  'selected'
                }`}
                onClick={() => setSortOption('pure_special')}
              >
                <h6>Pure Fiction</h6>
              </div>
              <div
                className={`option apo ${
                  (sortOption === 'apo' || sortOption === 'apo_special') &&
                  'selected'
                }`}
                onClick={() => setSortOption('apo_special')}
              >
                <h6>Apocalyptic Shadow</h6>
              </div>
            </div>
          </>
        )}
        {(sortOption === 'pure' || sortOption === 'pure_special') && (
          <div className="data-info-banner">
            <h4>
              Check our <span>Pure Fiction</span> Analytics for historical data!
            </h4>
            <Link to="/star-rail/pure-fiction">
              <Button variant="primary">Learn more</Button>
            </Link>
          </div>
        )}
        {(sortOption === 'apo' || sortOption === 'apo_special') && (
          <div className="data-info-banner">
            <h4>
              Check our <span>Apocalyptic Shadow</span> Analytics for historical
              data!
            </h4>
            <Link to="/star-rail/apocalyptic-shadow">
              <Button variant="primary">Learn more</Button>
            </Link>
          </div>
        )}
        {(sortOption === 'moc' || sortOption === 'moc_special') && (
          <div className="data-info-banner">
            <h4>
              Check our <span>Memory of Chaos</span> Analytics for historical
              data!
            </h4>
            <Link to="/star-rail/memory-of-chaos">
              <Button variant="primary">Learn more</Button>
            </Link>
          </div>
        )}
        <div className="employees-filter-bar hsr with-color">
          <FilterBar config={filterConfig} onChange={handleFilterUpdates} />
        </div>
        <div className="custom-tier-list-hsr">
          <div className="custom-tier-header">
            <div className="tier-rating">
              <span>&nbsp;</span>
            </div>
            <div className="custom-tier-container">
              <div className="custom-header dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
              </div>
              <div className="custom-header debuffer">
                <FontAwesomeIcon icon={faVirus} width="18" /> Support DPS
              </div>
              <div className="custom-header support">
                <FontAwesomeIcon icon={faFlask} width="18" /> Amplifier
              </div>
              <div className="custom-header sustain">
                <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Sustain
              </div>
            </div>
          </div>
          <CustomPopover
            enabled
            customClass="character-hover-box hsr"
            popover={
              <div className="hsr-meta-popover">
                <div>
                  <FontAwesomeIcon icon={faStar} width="30" className="apex" />
                  <FontAwesomeIcon icon={faStar} width="30" className="apex" />
                  <FontAwesomeIcon icon={faStar} width="30" className="apex" />
                </div>
                <h4 className="apex">Apex Characters</h4>
                <p>
                  Characters in this bracket are the cream of the crop. They can
                  do outrageous amount of damage, provide massive buffs (or
                  debuffs) or keep the team alive no matter what the enemy
                  throws at them. They simply can achieve the best, most
                  consistent and accessible results in their best teams,
                  allowing you to easily clear the endgame.
                </p>
              </div>
            }
          >
            <div className={`meta-line`}>
              <h5 className="meta-header apex">
                <FontAwesomeIcon icon={faAnglesDown} width="18" /> Apex
                characters <FontAwesomeIcon icon={faAnglesDown} width="18" />
              </h5>
            </div>
          </CustomPopover>
          <div className="custom-tier tier-0 first">
            <div className="tier-rating t-0">
              <span>T0</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
              </div>
              <div className="custom-tier-burst dps">
                {visibleCharacters
                  .filter((emp) => emp[sortOption + '_rating'] === 11)
                  .filter((emp) => emp.cat === 'DPS')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                            tags={emp.tags}
                            role={emp.cat}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile debuffer">
                <FontAwesomeIcon icon={faVirus} width="18" /> Support DPS
              </div>
              <div className="custom-tier-burst debuffer">
                {visibleCharacters
                  .filter((emp) => emp[sortOption + '_rating'] === 11)
                  .filter((emp) => emp.cat === 'Specialist')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                            tags={emp.tags}
                            role={emp.cat}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile support">
                <FontAwesomeIcon icon={faFlask} width="18" /> Amplifier
              </div>
              <div className="custom-tier-burst support">
                {visibleCharacters
                  .filter((emp) => emp[sortOption + '_rating'] === 11)
                  .filter((emp) => emp.cat === 'Amplifier')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                            tags={emp.tags}
                            role={emp.cat}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile sustain">
                <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Sustain
              </div>
              <div className="custom-tier-burst sustain">
                {visibleCharacters
                  .filter((emp) => emp[sortOption + '_rating'] === 11)
                  .filter((emp) => emp.cat === 'Sustain')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                            tags={emp.tags}
                            role={emp.cat}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-05">
            <div className="tier-rating t-05">
              <span>T0.5</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
              </div>
              <div className="custom-tier-burst dps">
                {visibleCharacters
                  .filter((emp) => emp[sortOption + '_rating'] === 10)
                  .filter((emp) => emp.cat === 'DPS')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                            tags={emp.tags}
                            role={emp.cat}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile debuffer">
                <FontAwesomeIcon icon={faVirus} width="18" /> Support DPS
              </div>
              <div className="custom-tier-burst debuffer">
                {visibleCharacters
                  .filter((emp) => emp[sortOption + '_rating'] === 10)
                  .filter((emp) => emp.cat === 'Specialist')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                            tags={emp.tags}
                            role={emp.cat}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile support">
                <FontAwesomeIcon icon={faFlask} width="18" /> Amplifier
              </div>
              <div className="custom-tier-burst support">
                {visibleCharacters
                  .filter((emp) => emp[sortOption + '_rating'] === 10)
                  .filter((emp) => emp.cat === 'Amplifier')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                            tags={emp.tags}
                            role={emp.cat}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile sustain">
                <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Sustain
              </div>
              <div className="custom-tier-burst sustain">
                {visibleCharacters
                  .filter((emp) => emp[sortOption + '_rating'] === 10)
                  .filter((emp) => emp.cat === 'Sustain')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                            tags={emp.tags}
                            role={emp.cat}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <CustomPopover
            enabled
            customClass="character-hover-box hsr"
            popover={
              <div className="hsr-meta-popover">
                <div>
                  <FontAwesomeIcon icon={faStar} width="30" className="meta" />
                  <FontAwesomeIcon icon={faStar} width="30" className="meta" />
                  <FontAwesomeIcon
                    icon={faStarEmpty}
                    width="30"
                    className="meta"
                  />
                </div>
                <h4 className="meta">Meta Characters</h4>
                <p>
                  Characters in this bracket make clearing the mode a breeze,
                  but compared to the Apex characters they require more work
                  from your side. Either their team options are more
                  restrictive, they require bigger Relic investment or they
                  possess a notable weakness or downside. Still, they are all
                  great and won't disappoint you if you give them what they need
                  to shine.
                </p>
              </div>
            }
          >
            <div className={`meta-line`}>
              <h5 className="meta-header meta">
                <FontAwesomeIcon icon={faAnglesDown} width="18" /> Meta
                characters <FontAwesomeIcon icon={faAnglesDown} width="18" />
              </h5>
            </div>
          </CustomPopover>
          <div className="custom-tier tier-1">
            <div className="tier-rating t-1">
              <span>T1</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
              </div>
              <div className="custom-tier-burst dps">
                {visibleCharacters
                  .filter((emp) => emp[sortOption + '_rating'] === 9)
                  .filter((emp) => emp.cat === 'DPS')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                            tags={emp.tags}
                            role={emp.cat}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile debuffer">
                <FontAwesomeIcon icon={faVirus} width="18" /> Support DPS
              </div>
              <div className="custom-tier-burst debuffer">
                {visibleCharacters
                  .filter((emp) => emp[sortOption + '_rating'] === 9)
                  .filter((emp) => emp.cat === 'Specialist')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                            tags={emp.tags}
                            role={emp.cat}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile support">
                <FontAwesomeIcon icon={faFlask} width="18" /> Amplifier
              </div>
              <div className="custom-tier-burst support">
                {visibleCharacters
                  .filter((emp) => emp[sortOption + '_rating'] === 9)
                  .filter((emp) => emp.cat === 'Amplifier')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                            tags={emp.tags}
                            role={emp.cat}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile sustain">
                <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Sustain
              </div>
              <div className="custom-tier-burst sustain">
                {visibleCharacters
                  .filter((emp) => emp[sortOption + '_rating'] === 9)
                  .filter((emp) => emp.cat === 'Sustain')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                            tags={emp.tags}
                            role={emp.cat}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-15">
            <div className="tier-rating t-15">
              <span>T1.5</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
              </div>
              <div className="custom-tier-burst dps">
                {visibleCharacters
                  .filter((emp) => emp[sortOption + '_rating'] === 8)
                  .filter((emp) => emp.cat === 'DPS')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                            tags={emp.tags}
                            role={emp.cat}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile debuffer">
                <FontAwesomeIcon icon={faVirus} width="18" /> Support DPS
              </div>
              <div className="custom-tier-burst debuffer">
                {visibleCharacters
                  .filter((emp) => emp[sortOption + '_rating'] === 8)
                  .filter((emp) => emp.cat === 'Specialist')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                            tags={emp.tags}
                            role={emp.cat}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile support">
                <FontAwesomeIcon icon={faFlask} width="18" /> Amplifier
              </div>
              <div className="custom-tier-burst support">
                {visibleCharacters
                  .filter((emp) => emp[sortOption + '_rating'] === 8)
                  .filter((emp) => emp.cat === 'Amplifier')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                            tags={emp.tags}
                            role={emp.cat}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile sustain">
                <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Sustain
              </div>
              <div className="custom-tier-burst sustain">
                {visibleCharacters
                  .filter((emp) => emp[sortOption + '_rating'] === 8)
                  .filter((emp) => emp.cat === 'Sustain')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                            tags={emp.tags}
                            role={emp.cat}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-2">
            <div className="tier-rating t-2">
              <span>T2</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
              </div>
              <div className="custom-tier-burst dps">
                {visibleCharacters
                  .filter((emp) => emp[sortOption + '_rating'] === 7)
                  .filter((emp) => emp.cat === 'DPS')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                            tags={emp.tags}
                            role={emp.cat}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile debuffer">
                <FontAwesomeIcon icon={faVirus} width="18" /> Support DPS
              </div>
              <div className="custom-tier-burst debuffer">
                {visibleCharacters
                  .filter((emp) => emp[sortOption + '_rating'] === 7)
                  .filter((emp) => emp.cat === 'Specialist')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                            tags={emp.tags}
                            role={emp.cat}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile support">
                <FontAwesomeIcon icon={faFlask} width="18" /> Amplifier
              </div>
              <div className="custom-tier-burst support">
                {visibleCharacters
                  .filter((emp) => emp[sortOption + '_rating'] === 7)
                  .filter((emp) => emp.cat === 'Amplifier')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                            tags={emp.tags}
                            role={emp.cat}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile sustain">
                <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Sustain
              </div>
              <div className="custom-tier-burst sustain">
                {visibleCharacters
                  .filter((emp) => emp[sortOption + '_rating'] === 7)
                  .filter((emp) => emp.cat === 'Sustain')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                            tags={emp.tags}
                            role={emp.cat}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <CustomPopover
            enabled
            customClass="character-hover-box hsr"
            popover={
              <div className="hsr-meta-popover">
                <div>
                  <FontAwesomeIcon icon={faStar} width="30" className="niche" />
                  <FontAwesomeIcon
                    icon={faStarEmpty}
                    width="30"
                    className="niche"
                  />
                  <FontAwesomeIcon
                    icon={faStarEmpty}
                    width="30"
                    className="niche"
                  />
                </div>
                <h4 className="niche">Niche Characters</h4>
                <p>
                  Characters in this bracket are lacking in one or many fields
                  of their role in the mode. These characters still possess the
                  ability to clear all content with the right build, endgame
                  blessing, team or enemy line-up but have much lower play rates
                  and success rates than Meta and Apex characters.
                </p>
              </div>
            }
          >
            <div className={`meta-line`}>
              <h5 className="meta-header niche">
                <FontAwesomeIcon icon={faAnglesDown} width="18" /> Niche
                characters <FontAwesomeIcon icon={faAnglesDown} width="18" />
              </h5>
            </div>
          </CustomPopover>
          <div className="custom-tier tier-3">
            <div className="tier-rating t-3">
              <span>T3</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
              </div>
              <div className="custom-tier-burst dps">
                {visibleCharacters
                  .filter((emp) => emp[sortOption + '_rating'] === 6)
                  .filter((emp) => emp.cat === 'DPS')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                            tags={emp.tags}
                            role={emp.cat}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile debuffer">
                <FontAwesomeIcon icon={faVirus} width="18" /> Support DPS
              </div>
              <div className="custom-tier-burst debuffer">
                {visibleCharacters
                  .filter((emp) => emp[sortOption + '_rating'] === 6)
                  .filter((emp) => emp.cat === 'Specialist')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                            tags={emp.tags}
                            role={emp.cat}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile support">
                <FontAwesomeIcon icon={faFlask} width="18" /> Amplifier
              </div>
              <div className="custom-tier-burst support">
                {visibleCharacters
                  .filter((emp) => emp[sortOption + '_rating'] === 6)
                  .filter((emp) => emp.cat === 'Amplifier')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                            tags={emp.tags}
                            role={emp.cat}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile sustain">
                <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Sustain
              </div>
              <div className="custom-tier-burst sustain">
                {visibleCharacters
                  .filter((emp) => emp[sortOption + '_rating'] === 6)
                  .filter((emp) => emp.cat === 'Sustain')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                            tags={emp.tags}
                            role={emp.cat}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-4">
            <div className="tier-rating t-4">
              <span>T4</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
              </div>
              <div className="custom-tier-burst dps">
                {visibleCharacters
                  .filter((emp) => emp[sortOption + '_rating'] === 5)
                  .filter((emp) => emp.cat === 'DPS')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                            tags={emp.tags}
                            role={emp.cat}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile debuffer">
                <FontAwesomeIcon icon={faVirus} width="18" /> Support DPS
              </div>
              <div className="custom-tier-burst debuffer">
                {visibleCharacters
                  .filter((emp) => emp[sortOption + '_rating'] === 5)
                  .filter((emp) => emp.cat === 'Specialist')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                            tags={emp.tags}
                            role={emp.cat}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile support">
                <FontAwesomeIcon icon={faFlask} width="18" /> Amplifier
              </div>
              <div className="custom-tier-burst support">
                {visibleCharacters
                  .filter((emp) => emp[sortOption + '_rating'] === 5)
                  .filter((emp) => emp.cat === 'Amplifier')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                            tags={emp.tags}
                            role={emp.cat}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile sustain">
                <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Sustain
              </div>
              <div className="custom-tier-burst sustain">
                {visibleCharacters
                  .filter((emp) => emp[sortOption + '_rating'] === 5)
                  .filter((emp) => emp.cat === 'Sustain')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                            tags={emp.tags}
                            role={emp.cat}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <CustomPopover
            enabled
            customClass="character-hover-box hsr"
            popover={
              <div className="hsr-meta-popover">
                <div>
                  <FontAwesomeIcon
                    icon={faFaceSadTear}
                    width="30"
                    className="forgotten"
                  />
                </div>
                <h4 className="forgotten">The Forgotten Ones</h4>
                <p>
                  Characters in this bracket struggle. A lot. Either their kits
                  simply don't work in the mode or they require a ton of
                  investment and love to be able to work. Just avoid them unless
                  your husbando or waifu landed here and you believe in the
                  power of love.
                </p>
              </div>
            }
          >
            <div className={`meta-line`}>
              <h5 className="meta-header forgotten">
                <FontAwesomeIcon icon={faAnglesDown} width="18" /> The Forgotten
                Ones <FontAwesomeIcon icon={faAnglesDown} width="18" />
              </h5>
            </div>
          </CustomPopover>
          <div className="custom-tier tier-5">
            <div className="tier-rating t-5">
              <span>T5</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
              </div>
              <div className="custom-tier-burst dps">
                {visibleCharacters
                  .filter((emp) => emp[sortOption + '_rating'] === 4)
                  .filter((emp) => emp.cat === 'DPS')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                            tags={emp.tags}
                            role={emp.cat}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile debuffer">
                <FontAwesomeIcon icon={faVirus} width="18" /> Support DPS
              </div>
              <div className="custom-tier-burst debuffer">
                {visibleCharacters
                  .filter((emp) => emp[sortOption + '_rating'] === 4)
                  .filter((emp) => emp.cat === 'Specialist')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                            tags={emp.tags}
                            role={emp.cat}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile support">
                <FontAwesomeIcon icon={faFlask} width="18" /> Amplifier
              </div>
              <div className="custom-tier-burst support">
                {visibleCharacters
                  .filter((emp) => emp[sortOption + '_rating'] === 4)
                  .filter((emp) => emp.cat === 'Amplifier')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                            tags={emp.tags}
                            role={emp.cat}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile sustain">
                <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Sustain
              </div>
              <div className="custom-tier-burst sustain">
                {visibleCharacters
                  .filter((emp) => emp[sortOption + '_rating'] === 4)
                  .filter((emp) => emp.cat === 'Sustain')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                            tags={emp.tags}
                            role={emp.cat}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
        <div className="fuse-ad-placeholder" data-fuse="22844297241"></div>
      </div>
    </DashboardLayout>
  );
};

export default HSRTierListPage;

export const Head: React.FC = () => (
  <Seo
    title="Tier List | Honkai: Star Rail | Prydwen Institute"
    description="The most popular tier list for Honkai: Star Rail that rates all available characters by their performance in Memory of Chaos, Pure Fiction and Apocalyptic Shadow. Find out who is the best!"
  />
);
